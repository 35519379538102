const searchableNames = [
    { label: `Harsh Tiwari, Full Stack Developer `, value: "64db7e17ba1e7c84f1650173" },
    { label: "Mohd Aftaab, Java Developer", value: "64ddbc72076b57179d89b679" },
    { label: "Ayon Somaddar, Machine Learning", value: "64e1c830c0fad24723c2adf9"},
    { label: "Preeti Pandey, Graphic Designer", value: "64e36ef845454656ca3dc8cc" },
    { label: "Depanshu, software developer", value: "64e9c91f948df2c49437cbdc" },


];
module.exports = searchableNames;

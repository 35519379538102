const skills = [
   // Web Development
    { label: `Web  Development `, value: "Web Development" },
    { label: "SEO", value: "SEO" },
    { label: "Social Media Marketing", value: "Social Media Marketing" },
    { label: "Data Analyst", value: "Data Analyst" },
    { label: "Data Engineer", value: "Data Engineer" },
    { label: "Machine Learning", value: "Machine Learning" },
    { label: "Deep learning", value: "Deep learning" },
    { label: "Java Developer", value: "Java Developer" },
  { label: "Python Developer", value: "Python Developer" },
  { label: "Ruby Developer", value: "Ruby Developer" },
  { label: "PHP Developer", value: "PHP Developer" },
  { label: "C++ Developer", value: "C++ Developer" },
  { label: "React Native", value: "React Native" },
  { label: "Angular Developer", value: "Angular Developer" },
  { label: "Vue.js Developer", value: "Vue.js Developer" },
  { label: "WordPress Developer", value: "WordPress Developer" },
  { label: "Shopify Developer", value: "Shopify Developer" },
  { label: "Magento Developer", value: "Magento Developer" },
  { label: "Node.js Developer", value: "Node.js Developer" },
  { label: "Express.js Developer", value: "Express.js Developer" },
  { label: "Django Developer", value: "Django Developer" },
  { label: "Flask Developer", value: "Flask Developer" },
  { label: "Laravel Developer", value: "Laravel Developer" },
  { label: "Spring Developer", value: "Spring Developer" },
  { label: "Ruby on Rails Developer", value: "Ruby on Rails Developer" },
  { label: "Swift Developer", value: "Swift Developer" },
  { label: "Kotlin Developer", value: "Kotlin Developer" },
  { label: "Flutter Developer", value: "Flutter Developer" },
  { label: "Android Developer", value: "Android Developer" },
  { label: "iOS Developer", value: "iOS Developer" },
  { label: "Web Design", value: "Web Design" },
  { label: "Logo Design", value: "Logo Design" },
  { label: "Video Editing", value: "Video Editing" },
  { label: "Animation", value: "Animation" },
  { label: "Copywriting", value: "Copywriting" },
  { label: "SEO Writing", value: "SEO Writing" },
  { label: "Social Media Management", value: "Social Media Management" },
  { label: "Content Strategy", value: "Content Strategy" },
  { label: "Email Marketing", value: "Email Marketing" },
  { label: "Google Analytics", value: "Google Analytics" },
  { label: "Data Analysis", value: "Data Analysis" },
  { label: "Data Science", value: "Data Science" },
  { label: "Machine Learning Engineer", value: "Machine Learning Engineer" },
  { label: "Data Engineer", value: "Data Engineer" },
  { label: "Data Visualization", value: "Data Visualization" },
  { label: "Natural Language Processing",value: "Natural Language Processing",},
  { label: "Computer Vision", value: "Computer Vision" },
  { label: "Big Data", value: "Big Data" },
  { label: "Database Design", value: "Database Design" },
  { label: "Network Security", value: "Network Security" },
  { label: "Penetration Testing", value: "Penetration Testing" },
  { label: "Cloud Architecture", value: "Cloud Architecture" },
  { label: "Server Administration", value: "Server Administration" },
  { label: "Microservices", value: "Microservices" },
  { label: "Chatbot Development", value: "Chatbot Development" },
  { label: "Virtual Reality Development", value: "Virtual Reality Development",},
  { label: "Augmented Reality Development",value: "Augmented Reality Development",},
  { label: "Embedded Systems", value: "Embedded Systems" },
  { label: "UI Testing", value: "UI Testing" },
  { label: "Frontend Development", value: "Frontend Development" },
  { label: "Backend Development", value: "Backend Development" },
  { label: "Full Stack Development", value: "Full Stack Development" },
  { label: "Mobile App Development", value: "Mobile App Development" },
  { label: "UI/UX Design", value: "UI/UX Design" },
  { label: "Graphic Design", value: "Graphic Design" },
  { label: "Content Writing", value: "Content Writing" },
  { label: "Digital Marketing", value: "Digital Marketing" },
  { label: "Project Management", value: "Project Management" },
  { label: "Business Analysis", value: "Business Analysis" },
  { label: "Cloud Computing", value: "Cloud Computing" },
  { label: "Cybersecurity", value: "Cybersecurity" },
  { label: "Database Administration", value: "Database Administration" },
  { label: "Network Engineering", value: "Network Engineering" },
  { label: "DevOps", value: "DevOps" },
  { label: "Artificial Intelligence", value: "Artificial Intelligence" },
  { label: "Blockchain", value: "Blockchain" },
  { label: "IoT (Internet of Things)", value: "IoT (Internet of Things)" },
  { label: "Quality Assurance", value: "Quality Assurance" },
  { label: "Salesforce Development", value: "Salesforce Development" },
  { label: "Game Development", value: "Game Development" },
  { label: "AR/VR Development", value: "AR/VR Development" },
  { label: "matLAB", value: "matLAB" },
];
module.exports = skills;
